//
import { getCookie, setCookie } from './cookies'

const COOKIE_ACE_SETTINGS = 'ace.settings'
const LOCALSTORAGE_KEY = 'shipperhq.settings'
/**
 * Read submenu state from cookies (legacy)
 */
export function getSubmenuState() {
    let submenu = {}

    let configCollapsed = getCookie('shq-configuration-collapsed')
    submenu['Configurations'] = configCollapsed === 'true' ? false : true

    return submenu
}

/**
 * @param collapse
 */
export function setConfigurationCollapsed(collapse) {
    setCookie('shq-configuration-collapsed', collapse ? 'true' : 'false')
}

/**
 * @returns {boolean}
 */
export function getSidebarVisibility() {
    return getCookieAceSetting('sidebar-collapsed')
}

/**
 * @returns {boolean}
 */
export function getCookieAceSetting(key) {
    let visible = true,
        aceSettingsStr = getCookie(COOKIE_ACE_SETTINGS)

    try {
        if (aceSettingsStr) {
            let aceSettings = JSON.parse(decodeURIComponent(aceSettingsStr))
            if (aceSettings[key] === 1) {
                return false
            }
        }
    } catch (e) {}

    return visible
}

export const setLocalStorage = (key, value) =>
    localStorage.setItem(`${LOCALSTORAGE_KEY}.${key}`, value)

export const getLocalStorage = (key) =>
    localStorage.getItem(`${LOCALSTORAGE_KEY}.${key}`)

/**
 * @param collapsed
 */
export function setCookieAceSetting(key, collapsed) {
    let aceSettings = {}
    try {
        let aceSettingsStr = getCookie(COOKIE_ACE_SETTINGS)
        if (aceSettingsStr) {
            aceSettings = JSON.parse(decodeURIComponent(aceSettingsStr))
        }
    } catch (e) {}

    if (collapsed) {
        aceSettings[key] = 1
    } else {
        delete aceSettings[key]
    }

    setCookie(COOKIE_ACE_SETTINGS, JSON.stringify(aceSettings))
}
