import axios from 'axios'

const csrfTokenEl = document.querySelector('meta[name="csrf-token"]')
const csrfToken = csrfTokenEl ? csrfTokenEl.getAttribute('content') : ''

export let axiosClient = axios.create({
    baseURL: '/',
    responseType: 'json',
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrfToken,
    },
})

const authorizeErrorValidation = (error) => {
    if (
        error.response.status === 401 &&
        document.location.pathname.match(/^\/ratesmgr/)
    ) {
        document.location.href = '/login'
    }
}

axiosClient.interceptors.response.use(
    (response) => Promise.resolve(response),
    (error) => {
        authorizeErrorValidation(error)

        return Promise.reject(error)
    }
)

export let axiosQuery = axios.create({
    baseURL: '/',
    responseType: 'json',
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrfToken,
    },
})

axiosQuery.interceptors.response.use(
    (response) => Promise.resolve(response),
    (error) => {
        authorizeErrorValidation(error)

        return Promise.reject(error?.response?.data || error)
    }
)

export const updateClient = (newClient) => {
    axiosClient = newClient
}
