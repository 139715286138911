import { applyMiddleware, createStore } from 'redux'
import reducer from './index'
import axiosMiddleware from 'redux-axios-middleware'
import { axiosClient } from 'net/ajax'
import { cacheUpdater } from './storeCache'
import { logger } from 'redux-logger'

let middlewares = [axiosMiddleware(axiosClient), cacheUpdater]

if (import.meta.env.MODE === 'development') {
    middlewares = [logger, ...middlewares]
}

const store = createStore(reducer, applyMiddleware(...middlewares))

export default store
