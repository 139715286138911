const SET_WEBSITE = 'ratecalc/SET_WEBSITE'
const SET_CUSTOMER_GROUP = 'ratecalc/SET_CUSTOMER_GROUP'
const SET_SHIPPING_ADDRESS = 'ratecalc/SET_SHIPPING_ADDRESS'
const SET_CART_ITEMS = 'ratecalc/SET_CART_ITEMS'
const ADD_CART_ITEM = 'ratecalc/ADD_CART_ITEM'
const UPDATE_REQUEST_DATA = 'ratecalc/UPDATE_REQUEST_DATA'
const UPDATE_REQUEST_DATA_FAIL = 'ratecalc/UPDATE_REQUEST_DATA_FAIL'
const UPDATE_REQUEST_DATA_SUCCESS = 'ratecalc/UPDATE_REQUEST_DATA_SUCCESS'
const DELETE_CART_ITEM = 'ratecalc/DELETE_CART_ITEM'
const RESET_ALL = 'ratecalc/RESET_ALL'
const SET_LOG = 'ratecalc/SET_LOG'

const SESSION_STORAGE_KEY = 'shq-ratecalc-store'

const initialState = {
    website: null,
    customerGroup: null,
    shippingAddressSet: false,
    shippingAddress: {},
    cart: [{}],
    request: {
        cart: [],
        shippingAddress: {},
    },
    log: {},
    resetValue: 0,
}

function reducer(state = initialState, action = {}) {
    const { type, result, error } = action

    switch (type) {
        case SET_WEBSITE:
            return {
                ...state,
                website: action.website,
            }

        case SET_CUSTOMER_GROUP:
            return {
                ...state,
                customerGroup: action.customerGroup,
            }

        case SET_SHIPPING_ADDRESS:
            return {
                ...state,
                shippingAddressSet: true,
                shippingAddress: action.address,
            }

        case SET_CART_ITEMS:
            return {
                ...state,
                cart: action.cart,
            }

        case ADD_CART_ITEM:
            return {
                ...state,
                cart: [...state.cart, action.item],
            }

        case UPDATE_REQUEST_DATA_SUCCESS:
            const {
                payload: { data: { time: requestTime = '' } = {} } = {},
            } = action

            return {
                ...state,
                request: {
                    cart: state.cart,
                    shippingAddress: state.shippingAddress,
                    requestTime,
                },
            }

        case UPDATE_REQUEST_DATA_FAIL:
            return {
                ...state,
                request: {
                    cart: state.cart,
                    shippingAddress: state.shippingAddress,
                    requestTime: 'Server Error',
                },
            }

        case DELETE_CART_ITEM:
            let newCart = [...state.cart]

            newCart.splice(action.itemIdx, 1)

            return {
                ...state,
                cart: newCart,
            }

        case SET_LOG:
            return {
                ...state,
                log: action.response,
            }

        case RESET_ALL:
            return {
                ...state,
                shippingAddressSet: false,
                cart: [{}],
                shippingAddress: {},
                customerGroup: undefined,
                request: {
                    cart: [],
                    shippingAddress: {},
                },
                resetValue: Math.random(),
            }

        default:
            return state
    }
}

function cachingReducer(state = undefined, action = {}) {

    if (window.sessionStorage === undefined) {
        return reducer(state, action)
    }

    if (!window.appData?.current_user) {
        return reducer(state, action)
    }

    const cacheKey = [SESSION_STORAGE_KEY, window.appData.current_user.id].join('-')

    if (!state) {
        try {
            const cachedData = JSON.parse(sessionStorage.getItem(cacheKey))
            state = cachedData ? cachedData : initialState
        } catch (e) {
            state = initialState
        }
    }

    let result = reducer(state, action)

    persistState(result, cacheKey)

    return result
}

// export default (window.sessionStorage && sessionStorage.getItem('__DEV')
//     ? cachingReducer
//     : reducer)
export default cachingReducer

/**
 * Persist redux store state in the sessionStorage
 * @param state
 */
function persistState(state, cacheKey) {
    let data = {
        ...state,
        request: {
            cart: [],
            shippingAddress: {},
        },
    }
    sessionStorage.setItem(cacheKey, JSON.stringify(data))
}

export const setShippingAddress = (address) => ({
    type: SET_SHIPPING_ADDRESS,
    address,
})

export const setWebsite = (website) => ({
    type: SET_WEBSITE,
    website,
})

export const setCustomerGroup = (customerGroup) => ({
    type: SET_CUSTOMER_GROUP,
    customerGroup,
})

export const setCartItems = (cart) => ({
    type: SET_CART_ITEMS,
    cart,
})

export const updateRequestData = () => ({
    type: UPDATE_REQUEST_DATA,
    payload: {
        request: {
            url: '/ratesmgr/ratecalc/server_time',
            method: 'get',
        },
    },
})

export const addCartItem = (item) => ({
    type: ADD_CART_ITEM,
    item,
})

export const deleteCartItem = (itemIdx) => ({
    type: DELETE_CART_ITEM,
    itemIdx,
})

export const resetAll = () => ({
    type: RESET_ALL,
})

export const setLog = (response) => ({
    type: SET_LOG,
    response,
})
