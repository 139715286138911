import DataCache from 'utils/cache'

export const dataCache = new DataCache()

export function saveInCache(page, data) {
    const dataCopy = { ...data }
    // strip messages from cached data so they won't reappear after dismissing
    dataCopy['messages'] = []
    dataCache.put(page, dataCopy)
}

/**
 * Updates react data cache with the current store state if updateStoreCache is specified in the action
 * @param store
 * @returns {function(*): function(*=): *}
 */
export const cacheUpdater = (store) => (next) => (action) => {
    let result = next(action)
    if (action.updateStoreCache) {
        const state = store.getState()
        saveInCache(location.pathname, state)
    }
    return result
}
