/* eslint-disable no-fallthrough */
import integrations from './integrations'
import { dataCache, saveInCache } from '../storeCache'
import logger from '../../utils/logger'
import resolveFields from '../../components/ui/form/fieldResolver'

const SET_SCOPE = 'app/SET_SCOPE'
const SWITCH_SCOPE = 'app/SWITCH_SCOPE'
const SWITCH_SCOPE_SUCCESS = 'app/SWITCH_SCOPE_SUCCESS'
const SWITCH_SCOPE_FAIL = 'app/SWITCH_SCOPE_FAIL'
const TOGGLE_FEATURE = 'app/TOGGLE_FEATURE'
const TOGGLE_FEATURE_FAIL = 'app/TOGGLE_FEATURE_FAIL'
const TOGGLE_FEATURE_SUCCESS = 'app/TOGGLE_FEATURE_SUCCESS'
const FETCH_APP_DATA = 'app/FETCH_APP_DATA'
const FETCH_APP_DATA_SUCCESS = 'app/FETCH_APP_DATA_SUCCESS'
const FETCH_APP_DATA_FAIL = 'app/FETCH_APP_DATA_FAIL'
const SAVE_SETTINGS = 'app/SAVE_SETTINGS'
const SAVE_SETTINGS_FAIL = 'app/SAVE_SETTINGS_FAIL'
const SAVE_SETTINGS_SUCCESS = 'app/SAVE_SETTINGS_SUCCESS'
const SAVE_ENTITY = 'app/SAVE_ENTITY'
export const SAVE_ENTITY_FAIL = 'app/SAVE_ENTITY_FAIL'
export const SAVE_ENTITY_SUCCESS = 'app/SAVE_ENTITY_SUCCESS'
const ACTIVATE_ENTITY = 'app/ACTIVATE_ENTITY'
const ACTIVATE_ENTITY_FAIL = 'app/ACTIVATE_ENTITY_FAIL'
const ACTIVATE_ENTITY_SUCCESS = 'app/ACTIVATE_ENTITY_SUCCESS'
const LOAD_STATES = 'app/LOAD_STATES'
const LOAD_STATES_FAIL = 'app/LOAD_STATES_FAIL'
const LOAD_STATES_SUCCESS = 'app/LOAD_STATES_SUCCESS'

const SHOW_NOTIFICATION = 'app/SHOW_NOTIFICATION'
const DISMISS_MESSAGE = 'app/DISMISS_MESSAGE'
const DISMISS_ALL_MESSAGES = 'app/DISMISS_ALL_MESSAGES'
const SHOW_MESSAGE = 'app/SHOW_MESSAGE'
const SHOW_MESSAGES = 'app/SHOW_MESSAGES'
const NETWORK_ACTION_START = 'app/NETWORK_ACTION_START'
const NETWORK_ACTION_END = 'app/NETWORK_ACTION_END'
const PUSH_APP_DATA = 'app/PUSH_APP_DATA'
const PREVENT_PAGE_LEAVE = 'app/PREVENT_PAGE_LEAVE'

// Used for handling copy/backup/delete scope requests
const FETCH_SCOPE_ACTION = 'app/FETCH_SCOPE_ACTION'
const FETCH_SCOPE_ACTION_FAIL = 'app/FETCH_SCOPE_ACTION_FAIL'
const FETCH_SCOPE_ACTION_SUCCESS = 'app/FETCH_SCOPE_ACTION_SUCCESS'
const FETCH_SCOPE_ACTION_PROGRESS = 'app/FETCH_SCOPE_ACTION_PROGRESS'
const FETCH_SCOPE_ACTION_PROGRESS_FAIL = 'app/FETCH_SCOPE_ACTION_PROGRESS_FAIL'
const FETCH_SCOPE_ACTION_PROGRESS_SUCCESS =
    'app/FETCH_SCOPE_ACTION_PROGRESS_SUCCESS'
const START_POLLING_SCOPE_ACTION_PROGRESS =
    'app/START_POLLING_SCOPE_ACTION_PROGRESS'
const STOP_POLLING_SCOPE_ACTION_PROGRESS =
    'app/STOP_POLLING_SCOPE_ACTION_PROGRESS'

const UPDATE_FEDEX_EULA_ACCEPTED_DATE = 'app/UPDATE_FEDEX_EULA_ACCEPTED_DATE'
const UPDATE_USER_SETTING = 'app/UPDATE_USER_SETTING'

const CLEAR_FLASH_MESSAGES = 'app/CLEAR_FLASH_MESSAGES'

const initialState = window.appData ?? {}

// TODO: Refactor this to properly get integrations
// initial state from the integrations reducer
if (!initialState.integrations) {
    const initialIntegrationState = {
        integrations: {
            recommended_carriers: [],
            available_integrations: [],
            configure: null,
            logos: {},
            selected_carrier: null,
            isShipStationWizardShowing: false,
        },
    }

    initialState.integrations = { ...initialIntegrationState }
}

if (!initialState.messages) {
    initialState.messages = []
}

const combineSubAppReducers = (reducers) => {
    return (state = initialState, action) => {
        return reducers.reduce((nextState, reducer) => {
            return reducer(nextState, action)
        }, state)
    }
}

function mergeErrors(payload, defaultText) {
    let text = defaultText
    if (payload.data.errors) {
        text = `Error: ${Object.values(payload.data.errors).join(', ')}`
    }

    return text
}

function reducer(state = initialState, action = {}) {
    const { type } = action

    switch (type) {
        case TOGGLE_FEATURE: {
            let messages = []

            let details = state.network_action_details

            if (!details) {
                details = {}
            }

            details.feature = action.feature

            dataCache.flush()

            return {
                ...state,
                messages,
                network_action_details: details,
                network_action: true,
            }
        }

        case PREVENT_PAGE_LEAVE:
            return {
                ...state,
                pageLeaveCallback: action.pageLeaveCallback,
            }

        case SET_SCOPE:
            return {
                ...state,
                scope: action.scope,
            }

        case SWITCH_SCOPE:
            return {
                ...state,
                scope_being_switched: true,
                network_action: true,
            }

        case NETWORK_ACTION_START:
            return {
                ...state,
                network_action: true,
            }

        case NETWORK_ACTION_END:
            return {
                ...state,
                network_action: false,
            }

        case SAVE_SETTINGS:
        // fall through
        case SAVE_ENTITY:
            dataCache.flush()
            return state

        case ACTIVATE_ENTITY:
        // falls through
        case FETCH_APP_DATA:
            if (action.hideNetworkAction) {
                return state
            }
            if (action.reload) {
                return {
                    ...state,
                    network_action: true,
                }
            } else {
                return {
                    ...state,
                    messages: [],
                    network_action: true,
                }
            }

        case TOGGLE_FEATURE_FAIL:
            return {
                ...state,
                network_action_details: removeFeatureNAD(state),
                network_action: false,
                toggle_feature_success: false,
            }

        case SAVE_ENTITY_FAIL: {
            let messages = []

            return {
                ...state,
                messages,
                scope_being_switched: false,
                network_action: false,
            }
        }

        case SAVE_SETTINGS_FAIL: {
            let messages = []

            try {
                const errors = action.error.response.data.errors
                if (errors) {
                    let fieldMap = {}
                    const { features, active_feature_name } = state
                    if (active_feature_name) {
                        fieldMap = resolveFields(
                            features[active_feature_name]?.form,
                            Object.keys(errors)
                        )
                    }

                    for (const key in errors) {
                        const message = errors[key]
                        if (message.type) {
                            messages.push(message)
                        } else {
                            const label = fieldMap[key]?.title || key
                            messages.push({
                                id: key + new Date(),
                                type: 'error',
                                text: `${label} - ${message}`,
                            })
                        }
                    }
                }
            } catch (e) {
                logger.error('error saving settings: %s', e)
            }

            if (!messages.length) {
                messages.push({
                    id: new Date(),
                    type: 'error',
                    text: 'Unexpected error occurred on server',
                })
            }

            return {
                ...state,
                messages,
                scope_being_switched: false,
                network_action: false,
            }
        }

        case FETCH_APP_DATA_FAIL:
        case SWITCH_SCOPE_FAIL:
        case ACTIVATE_ENTITY_FAIL: {
            let messages = []

            try {
                const errors = action.error.response.data.errors
                if (errors) {
                    for (const key in errors) {
                        const message = errors[key]
                        if (message.type) {
                            messages.push(message)
                        } else {
                            messages.push({
                                id: key + new Date(),
                                type: 'error',
                                text: `${key} - ${message}`,
                            })
                        }
                    }
                }
            } catch (e) {
                console.error(e)
            }

            if (!messages.length) {
                messages.push({
                    id: new Date(),
                    type: 'error',
                    text: 'Unexpected error occurred on server',
                })
            }

            return {
                ...state,
                messages,
                scope_being_switched: false,
                network_action: false,
            }
        }

        case FETCH_APP_DATA_SUCCESS: {
            // in case when the session has timed-out
            let data = action.payload.data ? action.payload.data : state
            if (!action.payload.data) {
                console.error('Empty app data received')
            } else {
                saveInCache(action.meta.previousAction.page, data)

                if (!state.frontend) {
                    const messages = Array.from(state.messages).concat(
                        data.messages
                    )

                    data.messages = messages
                }
            }

            if (!data.help) {
                // don't overwrite help links
                data.help = state.help
            }

            let initial = {}
            if (!action.replace) {
                initial = { ...state }
            }

            // skip meta tags if requested
            // (for example, when prefetching for a page on hover versus click)
            if (action.meta?.previousAction?.skipMeta) {
                data.meta_tags = state.meta_tags
            }

            return {
                ...initial,
                ...data,
                option_sources: {
                    ...state.option_sources,
                    ...data.option_sources,
                },
                scope_management: {
                    ...state.scope_management,
                    ...data.scope_management,
                },
                flash: [...(state?.flash || []), ...(data?.flash || [])],
                network_action: false,
                pageLeaveCallback: state.pageLeaveCallback,
            }
        }

        case PUSH_APP_DATA: {
            const data = action.data

            return {
                ...state,
                ...data,

                // retain from current state,
                // since in cache could be outdated:
                scope: state.scope,
                pageLeaveCallback: state.pageLeaveCallback,
            }
        }

        case SAVE_SETTINGS_SUCCESS: {
            if (action.payload.data && action.payload.data.messages) {
                let messages = Array.from(state.messages)

                if (action.payload.data.messages) {
                    messages.push(
                        ...action.payload.data.messages.map((message) => ({
                            ...message,
                            id: new Date(),
                        }))
                    )
                } else if (action.payload.data.error) {
                    messages.push({
                        id: `settings_${new Date().getTime()}`,
                        text: mergeErrors(
                            action.payload,
                            'Error while saving settings'
                        ),
                        type: 'error',
                    })
                }
                return {
                    ...state,
                    messages,
                    network_action: false,
                }
            }

            // something went wrong
            return {
                ...state,
                messages: [
                    {
                        id: `settings_${new Date().getTime()}`,
                        text: mergeErrors(
                            action.payload,
                            'Error while saving settings'
                        ),
                        type: 'error',
                    },
                ],
                network_action: false,
            }
        }

        case SAVE_ENTITY_SUCCESS: {
            let messages = Array.from(state.messages)

            const { meta: { previousAction: { successMessage } = {} } = {} } =
                action

            if (successMessage) {
                messages.push({
                    id: 'settings_' + new Date().getTime(),
                    text: successMessage,
                    type: 'info',
                })
            }

            return {
                ...state,
                messages,
                network_action: false,
            }
        }

        case ACTIVATE_ENTITY_SUCCESS: {
            const result = {
                ...state,
                network_action: false,
            }

            try {
                const active = action.meta.previousAction.active
                result.entity.data.active = active
            } catch (e) {
                console.error(e)
            }

            return result
        }

        case SWITCH_SCOPE_SUCCESS: {
            let newState = null

            if (
                action.payload.data.error === undefined &&
                action.payload.data.data
            ) {
                newState = {
                    ...state,
                    ...action.payload.data.data,
                    network_action: false,
                    scope_being_switched: false,
                }
            } else {
                newState = {
                    ...state,
                    network_action: false,
                    scope_being_switched: false,
                }
            }

            return newState
        }

        case TOGGLE_FEATURE_SUCCESS: {
            let newState = {
                ...state,
                network_action_details: removeFeatureNAD(state),
                network_action: false,
                toggle_feature_success: true,
            }

            if (action.payload.data.error === false) {
                newState.user_settings = {
                    ...newState.user_settings,
                    store_quote_order: action.payload.data.store_quote_order,
                }

                newState.enabled_features = action.payload.data.enabled_features
                newState.feature_docs = action.payload.data.feature_docs
                if ('store_quote_order' in action.payload.data) {
                    newState.current_user.store_quote_order =
                        action.payload.data.store_quote_order
                }
            } else {
                let messages = Array.from(newState.messages)

                messages.push({
                    id: 'limit' + new Date().getTime(),
                    text: action.payload.data.error,
                    type: 'error',
                })

                newState.messages = messages
            }

            return newState
        }

        case SHOW_MESSAGE: {
            let messages = Array.from(state.messages)
            const { message } = action
            if (!message.id) {
                message.id = 'custom' + new Date().getTime()
            }

            messages.push(message)

            return {
                ...state,
                messages,
            }
        }

        case SHOW_MESSAGES: {
            let messages = Array.from(state.messages)

            let idx = 0
            for (const message of action.messages) {
                if (!message.id) {
                    message.id = 'custom' + new Date().getTime() + idx
                    idx++
                }
                messages.push(message)
            }

            return {
                ...state,
                messages,
            }
        }

        case DISMISS_MESSAGE: {
            let messages = Array.from(state.messages)

            messages.splice(action.messageIdx, 1)

            return {
                ...state,
                messages,
            }
        }

        case DISMISS_ALL_MESSAGES: {
            // this actually interferes with the entity editor when backend response time is really high
            // TODO: move notifications out of redux
            return {
                ...state,
                messages: [],
            }
        }

        case SHOW_NOTIFICATION: {
            return {
                ...state,
                messages: [
                    {
                        id: 'notification_' + new Date().getTime(),
                        text: action.text,
                        type: action.notificationType,
                    },
                ],
            }
        }

        case LOAD_STATES:
            return {
                ...state,
                network_action: true,
            }

        case LOAD_STATES_FAIL:
            return {
                ...state,
                network_action: false,
            }

        case LOAD_STATES_SUCCESS: {
            let option_sources = {
                ...state.option_sources,
            }

            if (action.payload.data) {
                const { payload: { config } = {}, meta } = action
                const previousAction =
                    config && config.reduxSourceAction
                        ? config.reduxSourceAction
                        : meta.previousAction

                option_sources[`states_${previousAction.country}`] =
                    action.payload.data
            } else {
                // @todo
            }

            return {
                ...state,
                option_sources,
                network_action: false,
            }
        }

        case FETCH_SCOPE_ACTION: {
            const { fromScope = '', scopeAction = 'copy' } = action.payload
            return {
                ...state,
                scope_management: {
                    name: `${scopeAction}:${fromScope}`,
                    progress: 0,
                    in_progress: true,
                },
                isScopeActionLoading: true,
            }
        }
        case FETCH_SCOPE_ACTION_FAIL:
            return {
                ...state,
                ...action.payload,
                isScopeActionLoading: false,
                isScopeActionError: true,
            }
        case FETCH_SCOPE_ACTION_SUCCESS:
            return {
                ...state,
                ...action.payload,
                scope_management: {
                    ...state?.scope_management,
                    in_progress: false,
                    ...action.payload?.scope_management,
                },
                isScopeActionLoading: false,
                isScopeActionError: false,
            }
        case FETCH_SCOPE_ACTION_PROGRESS:
            return {
                ...state,
                scope_management: {
                    ...state?.scope_management,
                    progress: state?.scope_management?.progress,
                    in_progress: true,
                },
            }
        case FETCH_SCOPE_ACTION_PROGRESS_FAIL:
            return {
                ...state,
                ...action.payload,
                scope_management: {
                    ...action.payload.data,
                    in_progress: true,
                },
            }
        case FETCH_SCOPE_ACTION_PROGRESS_SUCCESS:
            return {
                ...state,
                scope_management: {
                    name: state?.scope_management?.name,
                    ...action.payload.data,
                    is_polling_progress: action.payload.data?.progress !== 100,
                },
            }
        case START_POLLING_SCOPE_ACTION_PROGRESS:
            return {
                ...state,
                scope_management: {
                    ...state.scope_management,
                    is_polling_progress: true,
                    in_progress: true,
                    result: '',
                    progress: 0,
                },
            }
        case STOP_POLLING_SCOPE_ACTION_PROGRESS:
            return {
                ...state,
                scope_management: {
                    ...state.scope_management,
                    is_polling_progress: false,
                    in_progress: false,
                },
            }
        case UPDATE_FEDEX_EULA_ACCEPTED_DATE:
            return {
                ...state,
                user_settings: {
                    ...state.user_settings,
                    fedex_eula_accepted_date: new Date(),
                },
            }
        case UPDATE_USER_SETTING:
            dataCache.flush()

            return {
                ...state,
                user_settings: {
                    ...state.user_settings,
                    [action.key]: action.value,
                },
            }

        case CLEAR_FLASH_MESSAGES:
            return {
                ...state,
                flash: [],
            }
        default:
            return state
    }
}

export const fetchScopeAction = (scopeAction, fromScope) => ({
    type: FETCH_SCOPE_ACTION,
    payload: {
        scopeAction,
        fromScope,
    },
})

export const fetchScopeActionSuccess = () => ({
    type: FETCH_SCOPE_ACTION_SUCCESS,
})

export const fetchScopeActionFail = () => ({
    type: FETCH_SCOPE_ACTION_FAIL,
})

export const fetchScopeActionProgress = () => ({
    type: FETCH_SCOPE_ACTION_PROGRESS,
    payload: {
        request: {
            url: '/ratesmgr/scopes/progress',
            method: 'get',
        },
    },
})

export const startPollingScopeActionProgress = () => ({
    type: START_POLLING_SCOPE_ACTION_PROGRESS,
})

export const stopPollingScopeActionProgress = () => ({
    type: STOP_POLLING_SCOPE_ACTION_PROGRESS,
})

// export default expiredSessionHandler(reducer)
export default combineSubAppReducers([
    expiredSessionHandler(reducer),
    integrations,
])

/**
 * Removes 'feature' from network_action_details
 * @param state
 * @returns {{}}
 */
function removeFeatureNAD(state) {
    let details = { ...state.network_action_details }
    if (!details) {
        details = {}
    }

    if (details.feature !== undefined) {
        delete details.feature
    }

    return details
}

/**
 * When session expires and user tries to navigate to some other page,
 * we get a redirect to /user/sign_in.json.
 *
 * @param handler
 * @returns {Function}
 */
function expiredSessionHandler(handler) {
    return function (state = initialState, action = {}) {
        if (action && action.type && action.type.includes('_SUCCESS')) {
            if (
                action.payload &&
                action.payload.request &&
                action.payload.request.responseURL?.includes(
                    '/user/sign_in.json'
                )
            ) {
                window.location.href = '/login'
                return {
                    ...state,
                }
            }
        }

        return handler(state, action)
    }
}

/**
 * Switches to another scope
 * @param newScope
 * @returns {{type: string, newScope: *, payload: {request: {url: string, method: string, data: {environment_scope: *}}}}}
 */
export const switchScope = (newScope) => ({
    type: SWITCH_SCOPE,
    newScope,
    payload: {
        request: {
            url: '/ratesmgr/environment_scopes.json',
            method: 'post',
            data: {
                environment_scope: newScope,
            },
        },
    },
})

export const setScope = (scope) => ({
    type: SET_SCOPE,
    scope,
})

/**
 * Enables or disables feature with name <feature>
 * @param feature Feature name to disable
 * @param options axios options for request, for example use { params: { some: 'query params' } }
 * @returns {{type: string, feature: *, payload: {request: {url: string, method: string}}}}
 */
export const toggleFeature = (feature, options) => ({
    type: TOGGLE_FEATURE,
    feature,
    payload: {
        request: {
            url: '/ratesmgr/features/' + feature + '/toggle.json',
            method: 'get',
            ...options,
        },
    },
})

/**
 * Fetch page-specific data when navigating between pages
 * @todo: add caching of page data
 * @param page
 * @param useCache
 * @param replace
 * @param hideNetworkAction
 * @param skipMeta - used for prefetching the page on hover
 * @returns {{type: string, payload: {request: {url: string, method: string}}}}
 */
export const fetchAppData = (
    page,
    useCache = true,
    replace = true,
    hideNetworkAction = false,
    skipMeta = false
) => {
    if (useCache) {
        const cached = dataCache.get(page)

        if (cached) {
            return {
                type: PUSH_APP_DATA,
                data: cached,
            }
        }
    }

    return {
        type: FETCH_APP_DATA,
        page: page,
        replace,
        skipMeta,
        hideNetworkAction,
        payload: {
            request: {
                url: page + '.json',
                method: 'get',
            },
        },
    }
}

/**
 * Shortcut to fetchAppData for the current page
 * @param useCache
 * @returns {*|{type, data}|{type, page, payload}}
 */
export const reloadAppData = (useCache = true, hideNetworkAction = false) => {
    const { pathname } = location
    const action = fetchAppData(pathname, useCache, true, hideNetworkAction)
    action.reload = true
    return action
}

/**
 * Saves feature settings
 * @param settingsId
 * @param data
 * @returns {{type: string, payload: {request: {url: string, method: string, data: *}}}}
 */
export const saveSettings = (settingsId, data) => ({
    type: SAVE_SETTINGS,
    payload: {
        request: {
            url: '/ratesmgr/features/-1',
            method: 'post',
            data,
        },
    },
})

/**
 * Displays message on the top of the page
 * @param message
 * @returns {{type: string, messageIdx: *}}
 */
export const showMessage = (message) => ({
    type: SHOW_MESSAGE,
    message,
})

/**
 * Displays messages from ajax response
 * @returns {{type: string, messageIdx: *}}
 * @param response
 */
export const showMessagesFromResponse = (response) => {
    if (response && response.data && response.data.messages) {
        return {
            type: SHOW_MESSAGES,
            messages: response.data.messages,
        }
    }
}

/**
 *
 * @param messageIdx - index of the message in the array of messages
 * @returns {{type: string, messageIdx: *}}
 */
export const dismissMessage = (messageIdx) => ({
    type: DISMISS_MESSAGE,
    messageIdx,
})

/**
 * @returns {{type: *}}
 */
export const dismissAllMessages = () => {
    return {
        type: DISMISS_ALL_MESSAGES,
    }
}

/**
 * @returns {{type: *}}
 */
export const networkActionStart = () => {
    return {
        type: NETWORK_ACTION_START,
    }
}

/**
 * @returns {{type: *}}
 */
export const networkActionEnd = () => {
    return {
        type: NETWORK_ACTION_END,
    }
}

/**
 * Populates store with supplied data
 * @param data
 * @returns {{type: string, data: *}}
 */
export const pushAppData = (data) => ({
    type: PUSH_APP_DATA,
    data,
})

/**
 * Loads states for the specified country
 * @param country - iso2 country code
 * @returns {{type: string, newScope: *, payload: {request: {url: string, method: string, data: {environment_scope: *}}}}}
 */
export const loadStates = (country) => ({
    type: LOAD_STATES,
    country,
    payload: {
        request: {
            url:
                typeof country === 'number' || !isNaN(parseInt(country))
                    ? `/ratesmgr/ratecalc/states_for_country?country_id=${country}`
                    : `/ratesmgr/ratecalc/states_for_country?country=${country}`,
            method: 'get',
        },
    },
})

/**
 * Saves specified entity
 * @param entityType
 * @param entityId
 * @param data
 * @param entityPath
 * @returns {{type: *, payload: {request: {url: string, method: string, data: *}}}}
 */
export const saveEntity = (
    entityType,
    entityId,
    data,
    entityPath,
    successMessage
) => ({
    type: SAVE_ENTITY,
    successMessage,
    payload: {
        request: {
            url: entityPath,
            method: entityId ? 'put' : 'post',
            headers: {
                'Keep-Flash': true,
            },
            data,
        },
    },
})

/**
 * Enables/Disables specified entity
 * @param entityPath
 * @param entityId
 * @param active
 */
export const activateEntity = (entityPath, entityId, active) => ({
    type: ACTIVATE_ENTITY,
    active,
    payload: {
        request: {
            url: entityPath + `/activate${active ? '?active=true' : ''}`,
            method: 'get',
        },
    },
})

/**
 *
 */
export const preventPageLeave = (pageLeaveCallback) => ({
    type: PREVENT_PAGE_LEAVE,
    pageLeaveCallback,
})

/**
 * Loads states for the specified country
 * @param country - iso2 country code
 * @returns {{type: string, newScope: *, payload: {request: {url: string, method: string, data: {environment_scope: *}}}}}
 */
export const showNotification = (text, notificationType = 'error') => ({
    type: SHOW_NOTIFICATION,
    text,
    notificationType,
})

export const updateFedexEulaAcceptedDate = () => ({
    type: UPDATE_FEDEX_EULA_ACCEPTED_DATE,
})

export const updateUserSetting = ({ key, value }) => ({
    type: UPDATE_USER_SETTING,
    key,
    value,
})

export const clearFlashMessages = () => ({
    type: CLEAR_FLASH_MESSAGES,
})
