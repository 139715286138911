//

export const TTL = 600 // 10 minutes

function diffSeconds(a, b) {
    return (a.getTime() - b.getTime()) / 1000
}

export default class Cache {
    constructor(storage = {}) {
        this.storage = storage
    }

    exists(key) {
        const entry = this.storage[key]
        const now = new Date()

        return entry ? diffSeconds(now, entry.updated_at) < TTL : false
    }

    get(key) {
        const entry = this.storage[key]
        const now = new Date()

        if (entry && diffSeconds(now, entry.updated_at) < TTL) {
            return entry.value
        }

        return null
    }

    put(key, value) {
        this.storage[key] = {
            value: value,
            updated_at: new Date(),
        }
    }

    remove(key) {
        delete this.storage[key]
    }

    flush() {
        this.storage = {}
    }
}
