import * as legacySettings from 'utils/legacySettings'

const TOGGLE_SIDEBAR = 'dashboard/TOGGLE_SIDEBAR'
const TOGGLE_SIDEMENU = 'dashboard/TOGGLE_SIDEMENU'
const TOGGLE_MOBILE_SIDEBAR = 'dashboard/TOGGLE_MOBILE_SIDEBAR'
const SET_BREADCRUMBS = 'dashboard/SET_BREADCRUMBS'
const CREATE_PORTAL = 'dashboard/CREATE_PORTAL'
const REMOVE_PORTAL = 'dashboard/REMOVE_PORTAL'
const RESET_PORTALS = 'dashboard/RESET_PORTALS'
const SET_SIDEBAR_VISIBLE = 'dashboard/SET_SIDEBAR_VISIBLE'
const SET_HELP_DOC_VISIBLE = 'dashboard/SET_HELP_DOC_VISIBLE'
const TOGGLE_FULL_SCREEN_MODE = 'dashboard/TOGGLE_FULL_SCREEN_MODE'
const SYNC_SIDEBAR = 'dashboard/SYNC_SIDEBAR'

const storePageKey = (key) => {
    let storeKey = key

    const isTableRatePage =
        window.location.pathname.includes('carriers') &&
        (window.location.pathname.includes('custom_carrier_rates') ||
            window.location.pathname.includes('custom_carrier_methods'))

    if (isTableRatePage) {
        storeKey = `tableRates/${storeKey}`
    }

    return storeKey
}

const setCookieAceSetting = (key, value) => {
    return legacySettings.setCookieAceSetting(storePageKey(key), value)
}

const setLocalStorage = (key, value) => {
    return legacySettings.setLocalStorage(storePageKey(key), value)
}

const getSidebarVisibility = () => {
    return legacySettings.getCookieAceSetting(storePageKey('sidebar-collapsed'))
}

const getLocalStorage = (key) => {
    return legacySettings.getLocalStorage(storePageKey(key))
}

export const PORTAL_MESSAGES = 'messages'

const initialState = {
    sidebarVisible: getSidebarVisibility(),
    helpDocVisible: getLocalStorage('helpdoc-collapsed') === 'false',
    sidebar: {
        submenu: legacySettings.getSubmenuState(),
    },
    breadcrumbs: [],
    mobileSidebarVisible: false,
    portals: {},
}

export default function reducer(state = initialState, action = {}) {
    const { type, result, error } = action

    switch (type) {
        case SYNC_SIDEBAR:
            return {
                ...state,
                sidebarVisible: getSidebarVisibility(),
                helpDocVisible:
                    getLocalStorage('helpdoc-collapsed') === 'false',
            }

        case SET_BREADCRUMBS:
            return {
                ...state,
                breadcrumbs: action.crumbs,
            }

        case CREATE_PORTAL: {
            const portals = {
                ...state.portals,
            }

            portals[action.name] = action.reference

            return {
                ...state,
                portals,
            }
        }

        case REMOVE_PORTAL: {
            const portals = {
                ...state.portals,
            }

            delete portals[action.name]

            return {
                ...state,
                portals,
            }
        }

        case RESET_PORTALS: {
            const portals = {}

            return {
                ...state,
                portals,
            }
        }

        case TOGGLE_SIDEBAR:
            setCookieAceSetting('sidebar-collapsed', state.sidebarVisible)

            return {
                ...state,
                sidebarVisible: !state.sidebarVisible,
            }

        case TOGGLE_FULL_SCREEN_MODE:
            const fullScreenMode = state.sidebarVisible || state.helpDocVisible

            setCookieAceSetting('sidebar-collapsed', fullScreenMode)
            setLocalStorage('helpdoc-collapsed', fullScreenMode)

            return {
                ...state,
                sidebarVisible: !fullScreenMode,
                helpDocVisible: !fullScreenMode,
            }

        case SET_SIDEBAR_VISIBLE:
            setCookieAceSetting('sidebar-collapsed', !action.sidebarVisible)

            return {
                ...state,
                sidebarVisible: action.sidebarVisible,
            }

        case SET_HELP_DOC_VISIBLE:
            setLocalStorage('helpdoc-collapsed', !action.helpDocVisible)

            return {
                ...state,
                helpDocVisible: action.helpDocVisible,
            }

        case TOGGLE_MOBILE_SIDEBAR:
            return {
                ...state,
                mobileSidebarVisible: !state.mobileSidebarVisible,
            }

        case TOGGLE_SIDEMENU:
            let sidebar = state.sidebar

            let menuState = sidebar.submenu[action.menu]
            if (menuState === undefined) {
                menuState = false
            }

            sidebar.submenu[action.menu] = !menuState

            if (action.menu === 'Configurations') {
                legacySettings.setConfigurationCollapsed(menuState)
            }

            return {
                ...state,
                sidebar,
            }

        default:
            return state
    }
}

export const toggleSidebar = () => ({
    type: TOGGLE_SIDEBAR,
})

export const setSidebarVisible = (sidebarVisible) => ({
    type: SET_SIDEBAR_VISIBLE,
    sidebarVisible,
})

export const setHelpDocsVisible = (helpDocVisible) => ({
    type: SET_HELP_DOC_VISIBLE,
    helpDocVisible,
})

export const toggleFullScreenMode = () => ({
    type: TOGGLE_FULL_SCREEN_MODE,
})

export const toggleMobileSidebar = () => {
    return {
        type: TOGGLE_MOBILE_SIDEBAR,
    }
}

export const toggleSidemenu = (menu) => ({
    type: TOGGLE_SIDEMENU,
    menu,
})

export const setBreadcrumbs = (crumbs) => ({
    type: SET_BREADCRUMBS,
    crumbs,
})

export const createPortal = (name, reference) => ({
    type: CREATE_PORTAL,
    name,
    reference,
})

export const removePortal = (name) => ({
    type: REMOVE_PORTAL,
    name,
})

export const resetPortals = () => ({
    type: RESET_PORTALS,
})

export const syncSidebar = () => ({
    type: SYNC_SIDEBAR,
})
