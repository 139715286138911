import dashboard from './modules/dashboard'
import app from './modules/app'
import ratecalc from './modules/ratecalc'
import { combineReducers } from 'redux'

export default combineReducers({
    app,
    dashboard,
    ratecalc,
})
