//
import util from 'util'
const levels = ['trace', 'debug', 'info', 'warn', 'error', 'fatal']
const noop = function () {}

const LOG_LEVEL = 'error'

function shouldLog(level) {
    return levels.indexOf(level) >= levels.indexOf(LOG_LEVEL)
}

function createLogger() {
    const logger = {}

    levels.forEach((level) => {
        logger[level] = shouldLog(level) ? log : noop

        function log() {
            let normalizedLevel

            switch (level) {
                case 'trace':
                    normalizedLevel = 'info'
                    break
                case 'debug':
                    normalizedLevel = 'info'
                    break
                case 'fatal':
                    normalizedLevel = 'error'
                    break
                default:
                    normalizedLevel = level
            }

            console[normalizedLevel](util.format.apply(util, arguments))
        }
    })

    return logger
}

export default createLogger()
