type FieldFilter = (field: FieldDefinition) => boolean
/**
 * Iterates over all form fields and finds specified ones
 * @param form
 * @param names - list of names, function for filtering fields or undefined to include all the fields
 * @returns {FieldMap}
 */
export default function resolveFields(
    form: FormDefinition,
    names?: string[] | FieldFilter
) {
    const result = {}

    const reduceChildren = (field, result) => {
        if (field.tabs) {
            result = field.tabs.reduce(reducer, result)
        }
        if (field.fields) {
            result = field.fields.reduce(reducer, result)
        } else if (field.columns) {
            for (const column of field.columns) {
                if (column.reduce) {
                    result = column.reduce(reducer, result)
                }
            }
        }

        return result
    }

    const reducer = (acc, field) => {
        const fieldName = field.text_field_name ?? field.name

        if (
            !names ||
            (Array.isArray(names) && names.includes(fieldName)) ||
            (typeof names === 'function' && names(field))
        ) {
            acc[fieldName] = field
        }
        // errors can be mapped to different field names
        if (
            !names ||
            (Array.isArray(names) && names.includes(field.error_field)) ||
            (typeof names === 'function' && names(field))
        ) {
            acc[fieldName] = field
        }
        acc = reduceChildren(field, acc)
        return acc
    }

    return reduceChildren(form, result)
}
