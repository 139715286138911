//
const CONFIGURE_CARRIER = 'integrations/CONFIGURE_CARRIER'
const END_CARRIER_CONFIGURATION = 'integrations/END_CARRIER_CONFIGURATION'
const INSTALL_CARRIER = 'integrations/INSTALL'
const INSTALL_CARRIER_SUCCESS = 'integrations/INSTALL_SUCCESS'
const INSTALL_CARRIER_FAIL = 'integrations/INSTALL_FAIL'
const REUSE_CARRIER_ACCOUNT = 'integrations/REUSE_CARRIER_ACCOUNT'
const REUSE_CARRIER_ACCOUNT_SUCCESS =
    'integrations/REUSE_CARRIER_ACCOUNT_SUCCESS'
const REUSE_CARRIER_ACCOUNT_FAIL = 'integrations/REUSE_CARRIER_ACCOUNT_FAIL'
const MARK_CARRIER_VALIDATED = 'integrations/MARK_CARRIER_VALIDATED'
const MARK_FEDEX_CARRIER_LIST_RATES =
    'integrations/MARK_FEDEX_CARRIER_LIST_RATES'
const LOAD_CARRIER_LOGOS = 'integrations/LOAD_CARRIER_LOGOS'
const LOAD_CARRIER_LOGOS_SUCCESS = 'integrations/LOAD_CARRIER_LOGOS_SUCCESS'
const LOAD_CARRIER_FORM = 'integrations/LOAD_CARRIER_FORM'
const LOAD_CARRIER_FORM_SUCCESS = 'integrations/LOAD_CARRIER_FORM_SUCCESS'
const SELECT_CARRIER = 'integrations/SELECT_CARRIER'
const CHANGE_LOCATION = 'integrations/CHANGE_LOCATION'
const SHOW_SHIPSTATION_WIZARD = 'integrations/SHOW_SHIPSTATION_WIZARD'
const HIDE_SHIPSTATION_WIZARD = 'integrations/HIDE_SHIPSTATION_WIZARD'
const INSTALL_UPS_MY_CHOICE = 'integrations/INSTALL_UPS_MY_CHOICE'

// NOTE: Initial state is getting overridden in the app module reducer
const initialState = {
    integrations: {
        recommended_carriers: [],
        available_integrations: [],
        configure: null,
        logos: {},
        selected_carrier: null,
        isShipStationWizardShowing: false,
    },
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CONFIGURE_CARRIER: {
            return {
                ...state,
                integrations: {
                    ...state.integrations,
                    configure: action.carrier,
                },
            }
        }

        // Used when displaying ShipStation
        case SHOW_SHIPSTATION_WIZARD: {
            return {
                ...state,
                integrations: {
                    ...state.integrations,
                    isShipStationWizardShowing: true,
                },
            }
        }

        case HIDE_SHIPSTATION_WIZARD: {
            return {
                ...state,
                integrations: {
                    ...state.integrations,
                    isShipStationWizardShowing: false,
                },
            }
        }

        case END_CARRIER_CONFIGURATION:
            return {
                ...state,
                integrations: {
                    ...state.integrations,
                    configure: null,
                },
            }

        case INSTALL_CARRIER:
        case REUSE_CARRIER_ACCOUNT:
            return state

        case INSTALL_CARRIER_FAIL:
        case REUSE_CARRIER_ACCOUNT_FAIL:
            if (
                action.error &&
                action.error.response &&
                action.error.response.headers &&
                action.error.response.headers['can-create'] === '0'
            ) {
                return {
                    ...state,
                    integrations: {
                        ...state.integrations,
                        can_install: false,
                    },
                }
            }

            return state

        case LOAD_CARRIER_LOGOS_SUCCESS: {
            const { integrations } = state

            return {
                ...state,
                integrations: {
                    ...integrations,
                    logos: action.payload.data,
                },
            }
        }

        case LOAD_CARRIER_FORM_SUCCESS: {
            const { integrations } = state
            const { data } = action.payload
            return {
                ...state,
                integrations: {
                    ...integrations,
                    ...data.integrations,
                },

                option_sources: {
                    ...state.option_sources,
                    ...data.option_sources,
                },
            }
        }

        case REUSE_CARRIER_ACCOUNT_SUCCESS:
            return { ...state }

        case INSTALL_CARRIER_SUCCESS: {
            const { integrations: oldIntegrations } = state

            const prevAction = action.meta.previousAction
            let integrations = {
                ...oldIntegrations,
                recommended_carriers: markCarrierInstalled(
                    oldIntegrations.recommended_carriers,
                    prevAction.carrier
                ),
                available_integrations: markCarrierInstalled(
                    oldIntegrations.available_integrations,
                    prevAction.carrier
                ),
            }

            if (
                action.payload &&
                action.payload.headers &&
                action.payload.headers['can-create'] === '0'
            ) {
                /* $FlowFixMe */
                integrations.can_install = false
            }

            return {
                ...state,
                integrations,
            }
        }

        case MARK_CARRIER_VALIDATED: {
            const { integrations: oldIntegrations } = state
            const newSelectedCarrier = oldIntegrations.selected_carrier

            if (newSelectedCarrier) {
                newSelectedCarrier.accounts = newSelectedCarrier.accounts.map(
                    (account) =>
                        action.account.id === account.id
                            ? { ...account, validated: true }
                            : account
                )
            }

            const integrations = {
                ...oldIntegrations,
                selected_carrier: newSelectedCarrier,
                recommended_carriers: markCarrierValidated(
                    oldIntegrations.recommended_carriers,
                    action.carrier
                ),
                available_integrations: markCarrierValidated(
                    oldIntegrations.available_integrations,
                    action.carrier
                ),
            }

            return {
                ...state,
                integrations,
            }
        }

        case MARK_FEDEX_CARRIER_LIST_RATES: {
            const { integrations: oldIntegrations } = state
            const newSelectedCarrier = oldIntegrations.selected_carrier

            if (newSelectedCarrier && action.account) {
                newSelectedCarrier.accounts = newSelectedCarrier.accounts.map(
                    (account) =>
                        action.account.id === account.id
                            ? { ...account, list_rates: true }
                            : account
                )
            }

            const integrations = {
                ...oldIntegrations,
                selected_carrier: newSelectedCarrier,
                recommended_carriers: updateCarrier(
                    oldIntegrations.recommended_carriers,
                    action.carrier,
                    { list_rates: true }
                ),
                available_integrations: updateCarrier(
                    oldIntegrations.available_integrations,
                    action.carrier,
                    { list_rates: true }
                ),
            }

            return {
                ...state,
                integrations,
            }
        }

        case SELECT_CARRIER:
            return {
                ...state,
                integrations: {
                    ...state.integrations,
                    selected_carrier: action.carrier,
                },
            }

        default:
            return state
    }
}

export function configureCarrier(carrier) {
    return {
        type: CONFIGURE_CARRIER,
        carrier,
    }
}

export function endCarrierConfiguration(carrier) {
    return {
        type: END_CARRIER_CONFIGURATION,
        carrier,
    }
}

/**
 * Creates a carrier entity
 * @param carrier
 * @param independent
 */
export function installCarrier(carrier, independent, options = {}) {
    return {
        type: INSTALL_CARRIER,
        updateStoreCache: true,
        carrier,
        payload: {
            request: {
                url: `/ratesmgr/carriers`,
                method: 'post',
                data: {
                    ratesmgr_carrier: {
                        carrier_category: 'small_package',
                        carrier_type: carrier.name,
                        name: carrier.title,
                        credentials_owner: independent
                            ? 'INDEPENDENT'
                            : 'OWNER',
                        active: 0,
                        ...options,
                    },
                    account_id: carrier.id,
                },
            },
        },
    }
}

export function installUPSMyChoice(enabled) {
    return {
        type: INSTALL_UPS_MY_CHOICE,
        updateStoreCache: true,
        payload: {
            request: {
                url: `/ratesmgr/carriers`,
                method: 'post',
                data: {
                    enabled,
                },
            },
        },
    }
}

export function reuseCarrierAccount(carrierId, accountId) {
    return {
        type: REUSE_CARRIER_ACCOUNT,
        payload: {
            request: {
                url: `/ratesmgr/carriers/reuse_account`,
                method: 'post',
                data: {
                    carrier_id: carrierId,
                    account_id: accountId,
                },
            },
        },
    }
}

export function showShipStationWizard() {
    return { type: SHOW_SHIPSTATION_WIZARD }
}

export function hideShipStationWizard() {
    return { type: HIDE_SHIPSTATION_WIZARD }
}

/**
 * Marks carrier as installed
 * @param carrier
 * @param account
 * @returns {{type: string, carrier: *, account: *}}
 */
export function markValidated(carrier, account) {
    return {
        type: MARK_CARRIER_VALIDATED,
        carrier,
        account,
        updateStoreCache: true,
    }
}

/**
 * Marks Fedex carrier as using standard list rates
 * @param carrier
 * @param account
 * @returns {{type: string, carrier: *, account: *}}
 */
export function markListRates(carrier, account) {
    return {
        type: MARK_FEDEX_CARRIER_LIST_RATES,
        carrier,
        account,
        updateStoreCache: true,
    }
}

/**
 * Loads carrier logos
 * @returns {{type: string, payload: *}}
 */
export function loadCarrierLogos() {
    return {
        type: LOAD_CARRIER_LOGOS,
        payload: {
            request: {
                url: `/marketplace/logos/`,
                method: 'get',
            },
        },
    }
}

/**
 * Loads carrier account form
 * @param carrier
 * @returns {{type: string, carrier: *}}
 */
export function loadCarrierForm(carrier) {
    return {
        type: LOAD_CARRIER_FORM,
        payload: {
            request: {
                url: `/ratesmgr/marketplace/carrier_form/${carrier?.name}`,
                method: 'get',
            },
        },
    }
}

export function changeMarketplaceLocation(newLocation) {
    return {
        type: CHANGE_LOCATION,
        payload: {
            request: {
                url: `/marketplace/change_location?location=${newLocation}`,
                method: 'get',
            },
        },
    }
}

/**
 * Selects a carrier
 * @param carrier
 */
export function selectCarrier(carrier) {
    return {
        type: SELECT_CARRIER,
        carrier,
    }
}

// TODO: use updateCarrier instead
function markCarrierInstalled(carriers, carrier) {
    if (!carriers) {
        return []
    }
    /* $FlowFixMe */
    return carriers.map((item) =>
        carrier.name === item.name ? { ...item, installed: true } : { ...item }
    )
}

// TODO: use updateCarrier instead
function markCarrierValidated(carriers, carrier) {
    if (!carriers) {
        return []
    }
    /* $FlowFixMe */
    return carriers.map((item) =>
        carrier.name === item.name ? { ...item, validated: true } : { ...item }
    )
}

function updateCarrier(carriers, carrier, data) {
    if (!carriers) {
        return []
    }
    /* $FlowFixMe */
    return carriers.map((item) =>
        carrier.name === item.name ? { ...item, ...data } : { ...item }
    )
}
